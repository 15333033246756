/* Font Loading */

@font-face {
  font-family: 'GT Ultra Fine Thin';
  src: url('fonts/GT-Ultra-Fine-Thin.woff2') format('woff2');
  font-weight: 100; 
  font-style: normal;
}

@font-face {
  font-family: 'GT Ultra Fine Thin Italic';
  src: url('fonts/GT-Ultra-Fine-Thin-Italic.woff2') format('woff2');
  font-weight: 100; 
  font-style: italic;
}

@font-face {
  font-family: 'GT Ultra Fine Light';
  src: url('fonts/GT-Ultra-Fine-Light.woff2') format('woff2');
  font-weight: 300; 
  font-style: normal;
}

@font-face {
  font-family: 'GT Ultra Fine Light Italic';
  src: url('fonts/GT-Ultra-Fine-Light-Italic.woff2') format('woff2');
  font-weight: 300; 
  font-style: italic;
}

@font-face {
  font-family: 'GT Ultra Fine Bold';
  src: url('fonts/GT-Ultra-Fine-Bold.woff2') format('woff2');
  font-weight: 700; 
  font-style: normal;
}

@font-face {
  font-family: 'GT Ultra Fine Bold Italic';
  src: url('fonts/GT-Ultra-Fine-Bold-Italic.woff2') format('woff2');
  font-weight: 700; 
  font-style: italic;
}

/* Basic Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  font-family: 'GT Ultra Fine Thin', sans-serif;
  background-color: #F0F4FF;
  color: #3C3F42;
  min-height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;
  overflow-x: hidden;
  padding: 20px;  
}

/* Element Styles  */

a {
  color: #3C3F42;
  font-style: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: #000f89;
}

main {
  width: 100%; 
  max-width: 1000px; 
  margin: 0 auto;
}

main p {
  font-family: 'GT Ultra Fine Thin', sans-serif; 
  text-align: left;
  margin: 10px 0;
  padding: 0 0 5px;
  font-size: 1.2em; 
}

main span.italic {
  font-family: 'GT Ultra Fine Thin Italic';
  font-style: italic;
}

main span.bold {
  font-family: 'GT Ultra Fine Light';
}

main span.underline {
  text-decoration: underline;
}

section {
  position: relative; 
  display: flex;
  flex-direction: column;
  align-items: center; 
  padding: 20px;
  width: 100%;
}

/* Class Styles */

.container {
  width: 100%;
  max-width: 100vw; 
  margin: 0 auto;
  padding: 0; 
}

footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  background-color: #F0F4FF;
  color: #3C3F42;
  font-family: 'GT Ultra Fine Thin', sans-serif;
}

.footer-arrow {
  font-size: 1rem;
}

.footer-back-to-top a {
  font-size: 1em;
  color: inherit; 
  text-decoration: none;
}

.footer-back-to-top a:hover {
  text-decoration: none;
  color: #000f89; 
}

.footer-copyright {
  font-size: 1em;
  margin-top: 10px;
}

.header-text {
  font-family: 'GT Ultra Fine Light', sans-serif;
  font-size: 2rem;
  text-align: center;
  justify-content: center;
  display: inline-block;
  margin: 0 15px;
  text-decoration: none; /* Remove underline from header text */
}

.line {
  display: block; 
  height: 1px; 
  background-color: black; 
  margin: 10px 0; 
  width: 50%; 
}

.navigation {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.navigation .header-text {
  font-family: 'GT Ultra Fine Light', sans-serif;
  font-size: 2rem;
  text-align: center;
  margin: 0 15px;
  color: inherit; 
}

.navigation .header-text:hover {
  text-decoration: none;
  color: #000f89;
}

.navigation .nav-items {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.navigation .nav-items a {
  font-size: 1.2em;
  margin: 0 15px;
  text-decoration: none;
  color: inherit; /* Inherit the color from parent elements */
}

.navigation .nav-items a:hover {
  text-decoration: none;
  color: #000f89; 
}

.sub-header {
  display: flex;
  flex-direction: column; 
  align-items: center;
  justify-content: center; 
  padding: 10px; 
  box-sizing: border-box; 
  width: 50%;
  margin: 0 auto;
}

.sub-header-text {
  margin: 0; 
  padding: 0 10px; 
  word-wrap: break-word; 
  width: 100%;
  text-align: center; 
  font-style: 'GT Ultra Fine Thin';
  font-size: 1.6em;
  font-weight:100;
}

.text-content {
  max-width: 680px; 
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  top: 10%;
}

/* Mobile Styles */
@media (max-width: 768px) {

  .header-text {
    font-size: 2rem;
  }

  .text-content {
    margin-left: 0; 
  }
  
  body {
    flex-direction: column;
  }

  main {
    margin-left: 0; 
    width: 100%; 
  }
}

/* Header Animation */

#js-header-text {
  display: inline-block;
  position: relative;
  text-decoration: none;
  color: inherit;
}

#js-header-text::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  margin: auto;
  background: #3C3F42;
  transition: width 1.2s linear;
}

#js-header-text:hover::after {
  width: 100%;
}

#js-header-text span {
  display: inline-block;
  transition: all .5s ease;
  visibility: visible;
  opacity: 1;
  --x: calc(100 * (random() - 0.5));
  --y: calc(100 * (random() - 0.5));
  --r: calc(720 * (random() - 0.5));
}