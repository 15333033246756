@font-face {
  font-family: GT Ultra Fine Thin;
  src: url("GT-Ultra-Fine-Thin.e880ba91.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: GT Ultra Fine Thin Italic;
  src: url("GT-Ultra-Fine-Thin-Italic.543d9ba8.woff2") format("woff2");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: GT Ultra Fine Light;
  src: url("GT-Ultra-Fine-Light.bf580117.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: GT Ultra Fine Light Italic;
  src: url("GT-Ultra-Fine-Light-Italic.9a0f0f06.woff2") format("woff2");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: GT Ultra Fine Bold;
  src: url("GT-Ultra-Fine-Bold.05131d50.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: GT Ultra Fine Bold Italic;
  src: url("GT-Ultra-Fine-Bold-Italic.e6ea8342.woff2") format("woff2");
  font-weight: 700;
  font-style: italic;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body, html {
  color: #3c3f42;
  scroll-behavior: smooth;
  background-color: #f0f4ff;
  flex-direction: column;
  min-height: 100%;
  margin: 0;
  padding: 20px;
  font-family: GT Ultra Fine Thin, sans-serif;
  display: flex;
  overflow-x: hidden;
}

a {
  color: #3c3f42;
  font-style: normal;
  text-decoration: none;
}

a:hover {
  color: #000f89;
  text-decoration: none;
}

main {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

main p {
  text-align: left;
  margin: 10px 0;
  padding: 0 0 5px;
  font-family: GT Ultra Fine Thin, sans-serif;
  font-size: 1.2em;
}

main span.italic {
  font-family: GT Ultra Fine Thin Italic;
  font-style: italic;
}

main span.bold {
  font-family: GT Ultra Fine Light;
}

main span.underline {
  text-decoration: underline;
}

section {
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
  display: flex;
  position: relative;
}

.container {
  width: 100%;
  max-width: 100vw;
  margin: 0 auto;
  padding: 0;
}

footer {
  color: #3c3f42;
  background-color: #f0f4ff;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  font-family: GT Ultra Fine Thin, sans-serif;
  display: flex;
}

.footer-arrow {
  font-size: 1rem;
}

.footer-back-to-top a {
  color: inherit;
  font-size: 1em;
  text-decoration: none;
}

.footer-back-to-top a:hover {
  color: #000f89;
  text-decoration: none;
}

.footer-copyright {
  margin-top: 10px;
  font-size: 1em;
}

.header-text {
  text-align: center;
  justify-content: center;
  margin: 0 15px;
  font-family: GT Ultra Fine Light, sans-serif;
  font-size: 2rem;
  text-decoration: none;
  display: inline-block;
}

.line {
  background-color: #000;
  width: 50%;
  height: 1px;
  margin: 10px 0;
  display: block;
}

.navigation {
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  display: flex;
}

.navigation .header-text {
  text-align: center;
  color: inherit;
  margin: 0 15px;
  font-family: GT Ultra Fine Light, sans-serif;
  font-size: 2rem;
}

.navigation .header-text:hover {
  color: #000f89;
  text-decoration: none;
}

.navigation .nav-items {
  justify-content: center;
  margin-top: 10px;
  display: flex;
}

.navigation .nav-items a {
  color: inherit;
  margin: 0 15px;
  font-size: 1.2em;
  text-decoration: none;
}

.navigation .nav-items a:hover {
  color: #000f89;
  text-decoration: none;
}

.sub-header {
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin: 0 auto;
  padding: 10px;
  display: flex;
}

.sub-header-text {
  word-wrap: break-word;
  text-align: center;
  font-style: "GT Ultra Fine Thin";
  width: 100%;
  margin: 0;
  padding: 0 10px;
  font-size: 1.6em;
  font-weight: 100;
}

.text-content {
  flex-wrap: wrap;
  max-width: 680px;
  margin: 0 auto;
  display: flex;
  top: 10%;
}

@media (width <= 768px) {
  .header-text {
    font-size: 2rem;
  }

  .text-content {
    margin-left: 0;
  }

  body {
    flex-direction: column;
  }

  main {
    width: 100%;
    margin-left: 0;
  }
}

#js-header-text {
  color: inherit;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

#js-header-text:after {
  content: "";
  background: #3c3f42;
  width: 0;
  height: 1px;
  margin: auto;
  transition: width 1.2s linear;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

#js-header-text:hover:after {
  width: 100%;
}

#js-header-text span {
  visibility: visible;
  opacity: 1;
  --x: calc(100 * (random()  - .5));
  --y: calc(100 * (random()  - .5));
  --r: calc(720 * (random()  - .5));
  transition: all .5s;
  display: inline-block;
}
/*# sourceMappingURL=index.1ac99f5f.css.map */
